import React, { useState, useEffect } from 'react';

const operationList = [
    { code: 'A', description: 'Technisch paneeltje vervangen', hours: 0.75 },
    { code: 'B', description: 'Binnenplaat vervangen', hours: 0.5 },
    { code: 'C', description: 'Buitenplaat vervangen', hours: 0.25 },
    { code: 'D', description: 'PVC vloerbedekking vervangen', hours: 1.5 },
    { code: 'E', description: 'Dak repareren', hours: 1 },
    { code: 'F', description: 'Paneel vervangen', hours: 1.5 },
    { code: 'G', description: 'Akoestische platen aanbrengen', hours: 0.75 },
    { code: 'H', description: 'Trap repareren', hours: 16 },
    { code: 'I', description: 'Trap versteviging 5KN onderkant', hours: 16 },
    { code: 'J', description: 'Trap versteviging 5KN bovenkant', hours: 8 },
    { code: 'K', description: 'Binendeur vervangen (brandwerend)', hours: 4 },
    { code: 'L', description: 'Dubbele buitendeur aanpassen/vervangen', hours: 2 },
    { code: 'M', description: 'Douchevloer repareren', hours: 4 },
];

const moduleOperations = {
    'FX-01': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-02/FX-02-A': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-03/FX-03-A/FX-03-ZK': ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    'FX-04/FX-04-A/FX-04-ZK': ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    'FX-05/FX-05-A/FX-05-ZK': ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
    'FX-06': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-07': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-08': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-10': ['A', 'B', 'C', 'D', 'E', 'F'],
    // 'FX-11': ['A', 'B', 'C', 'D', 'E', 'F'],
    'FX-12': ['A', 'B', 'C', 'D', 'E', 'F', 'H', 'I'],
    'FX-13': ['A', 'B', 'C', 'D', 'E', 'F', 'J', 'K'],
    'FX-14': ['A', 'B', 'C', 'D', 'E', 'F', 'L'],
    'FX-15': ['B', 'C', 'D', 'E', 'F'],
    'FX-16': ['A', 'B', 'C', 'D', 'E', 'M'],
};

const moduleList = Object.keys(moduleOperations);

const catThresholds = [
    { cat: 0, threshold: 0 },
    { cat: 1, threshold: 0.25 },
    { cat: 2, threshold: 1.25 },
    { cat: 3, threshold: 4.25 },
    { cat: 4, threshold: 6.5 },
    { cat: 5, threshold: 10 }
];

export default function Service() {
    const [selectedModule, setSelectedModule] = useState('');
    const [selectedOperations, setSelectedOperations] = useState([]);

    useEffect(() => {
        setSelectedOperations([]);
    }, [selectedModule]);

    const calculateTotalHours = () => {
        return selectedOperations.reduce((total, opCode) => {
            const operation = operationList.find(op => op.code === opCode);
            return total + operation.hours;
        }, 0);
    };

    const getCatNumber = (hours) => {
        for (let i = catThresholds.length - 1; i >= 0; i--) {
            if (hours >= catThresholds[i].threshold) {
                return catThresholds[i].cat;
            }
        }
        return 0;
    };

    const totalHours = calculateTotalHours();
    const catNumber = getCatNumber(totalHours);

    return (
        <div>
            <h1>Service</h1>
            <p>Selecteer een module en de bijbehorende werkzaamheden om de CAT te berekenen.</p>
            <div id='service-container'>
                <select className='custom-select' onChange={(e) => setSelectedModule(e.target.value)} value={selectedModule}>
                    <option value="">Select a module</option>
                    {moduleList.map((module) => (
                        <option key={module} value={module}>{module}</option>
                    ))}
                </select>
                <div className='checkbox-container'>
                {selectedModule && moduleOperations[selectedModule].map((opCode) => {
                    const operation = operationList.find(op => op.code === opCode);
                    return (
                        <div key={operation.code}>
                            <input
                                type="checkbox"
                                id={operation.code}
                                name={operation.code}
                                value={operation.code}
                                checked={selectedOperations.includes(operation.code)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedOperations([...selectedOperations, operation.code]);
                                    } else {
                                        setSelectedOperations(selectedOperations.filter(code => code !== operation.code));
                                    }
                                }}
                            />
                            <label htmlFor={operation.code}>{operation.description} ({operation.hours} uur)</label>
                        </div>
                    );
                })}
                </div>


                {selectedModule && (
                    <div>
                        <h3>CAT Nummer: {catNumber}</h3>
                        <p>Totale uren: {totalHours}</p>

                    </div>
                )}
            </div>
        </div>
    )
}
